<template>
  <div v-loading="loading">
    <el-tooltip :content="fieldPlaceholder" placement="top-start">
      <el-select
        v-model="selectedFileds[filter]"
        @change="updateEntityData"
        clearable
        filterable
        multiple
        collapse-tags
        :placeholder="fieldPlaceholder"
        size="mini"
      >
        <el-option
          value="ALL"
          label="All"
          v-if="allEntityDataList && allEntityDataList.length"
        >
        </el-option>
        <el-option
          v-for="(temp, i) in allEntityDataList"
          :key="temp._id + '_' + i"
          :value="temp._id"
          :label="getLabel(temp, i)"
          >{{ getLabel(temp, i) }}
        </el-option>
      </el-select>
    </el-tooltip>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: ["entityId", "fieldPlaceholder", "filter", "selectedFileds", "fromCD"],
  computed: {
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
      "getEntitiesDataErrors",
    ]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
  },
  watch: {},
  data() {
    return {
      currentEntity: null,
      loading: false,
      allEntityDataList: [],
      selected_filter: "",
    };
  },
  async mounted() {
    this.loading = true;
    if (
      !this.selectedFileds[this.filter] ||
      !this.selectedFileds[this.filter].length
    ) {
      // this.selectedFileds[this.filter] = ["ALL"];
    }
    await this.fetchEntityById(this.entityId);
    this.loading = false;
  },
  methods: {
    updateEntityData(value) {
      if (value.indexOf("ALL") != -1 && value.length > 1) {
        let i = value.indexOf("ALL");
        this.selectedFileds[this.filter].splice(i, 1);
        let v = this.selectedFileds[this.filter];
        this.$emit("entityFilterUpdated", {
          v,
          filter: this.filter,
        });
      } else {
        this.$emit("entityFilterUpdated", {
          value,
          filter: this.filter,
        });
      }
    },
    async fetchEntityById(entityId) {
      await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
        entity_id: entityId,
      });
      if (this.getEntityById) {
        this.currentEntity = this.getEntityById;
        this.fetchEntitiesDataForTable(entityId);
      } else {
        this.loading = false;
      }
    },
    async fetchEntitiesDataForTable(entityId) {
      this.loading = true;
      let params = {
        entity_id: entityId,
        template_fields_data: this.currentEntity.templates.map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: [],
      };
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.getEntityRecordsForTable) {
        this.allEntityDataList = this.getEntityRecordsForTable.data;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name = "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          name =
            item.entityData &&
            primaryField.template_id &&
            item.entityData[primaryField.template_id]
              ? item.entityData[primaryField.template_id][key + "/name"]
                ? item.entityData[primaryField.template_id][key + "/name"]
                : item.entityData[primaryField.template_id][key]
                ? primaryField.input_type == "DATE"
                  ? moment(
                      item.entityData[primaryField.template_id][key]
                    ).format(this.getDefaultDateFormat)
                  : primaryField.input_type == "DATE_TIME"
                  ? moment(
                      item.entityData[primaryField.template_id][
                        primaryField.key
                      ]
                    ).format(this.getDefaultDateFormat + " HH:mm:ss")
                  : item.entityData[primaryField.template_id][key]
                : "data" + i
              : "data" + i;
        }
      }
      return name;
    },
  },
};
</script>
